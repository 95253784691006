import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { environment } from '@my-toolbox-fe/environments/environment';

function getErrorMessage(error: HttpErrorResponse) {
  switch (error.status) {
    case 401:
      return 'Unauthorized';
    case 403:
      return 'Forbidden';
    default:
      return `Unknown error: ${error.message}`;
  }
}

function extractErrorDetails(wwwAuthenticateHeader: string | null): {
  errorCode: string | null;
  errorDescription: string | null;
} {
  const errorMatch = wwwAuthenticateHeader?.match(/error="([^"]+)"/);
  const errorDescriptionMatch = wwwAuthenticateHeader?.match(/error_description="([^"]+)"/);

  return {
    errorCode: errorMatch ? errorMatch[1] : null,
    errorDescription: errorDescriptionMatch ? errorDescriptionMatch[1] : null,
  };
}

function setAuthErrorMessage(errorCode: string | null, errorDescription: string | null) {
  console.error(errorCode, errorDescription);

  if (errorCode === 'invalid_username') {
    sessionStorage.setItem(
      'auth-error-message',
      `Your account is not yet set up in MyPT.<br>Please reach out to your local Atlas Copco Customer Center to have your MyPT account created.`,
    );
  } else if (errorCode === 'invalid_token') {
    sessionStorage.setItem(
      'auth-error-message',
      `Your session has expired.<br>Please log in again to continue.`,
    );
  } else {
    sessionStorage.setItem(
      'auth-error-message',
      `An error has occurred.<br>It seems there is an issue with your account or profile setup. Please contact the MyPT support team or your local Atlas Copco Customer Center for assistance.`,
    );
  }
}

function handle403Forbidden() {
  sessionStorage.setItem(
    'auth-error-message',
    'Your profile is not set up correctly in MyPT.<br>Please contact the MyPT support team to resolve this issue.',
  );
}

function cleanupSessionStorageForAuth() {
  sessionStorage.removeItem('auth-status');
  sessionStorage.removeItem('auth-error-message');
}

function logoutUser(msalService: MsalService, router: Router) {
  const account = msalService.instance.getActiveAccount();
  if (account) {
    msalService.instance.logoutRedirect({ account }).then(async () => {
      cleanupSessionStorageForAuth();
      await router.navigateByUrl('/login');
    });
  }
}

export const unauthorizedException: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const router = inject(Router);
  const msalService = inject(MsalService);

  cleanupSessionStorageForAuth();

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401 || error.status === 403) {
        if (error.url?.includes(environment.baseUrl)) {
          sessionStorage.setItem('auth-status', `${error.status}`);
          const wwwAuthenticateHeader = error.headers.get('www-authenticate');
          if (wwwAuthenticateHeader) {
            const { errorCode, errorDescription } = extractErrorDetails(wwwAuthenticateHeader);
            setAuthErrorMessage(errorCode, errorDescription);
          } else if (error.status === 403) {
            handle403Forbidden();
          }
        } else {
          cleanupSessionStorageForAuth();
        }

        msalService.handleRedirectObservable().subscribe(console.warn);
        logoutUser(msalService, router);

        return throwError(() => new Error(getErrorMessage(error)));
      }

      return throwError(() => error);
    }),
  );
};
