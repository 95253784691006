@if (isLoading()) {
  <mt-loader />
}

<div class="background-image">
  <img
    alt="sign-in background"
    sizes="(max-width: 3840px) 100vw, 3840px"
    src="./assets/images/bg-image/atlas-copco-group-center-sickla_nxzbzn_c_scale,w_1654.png"
    srcset="
      ./assets/images/bg-image/atlas-copco-group-center-sickla_nxzbzn_c_scale,w_480.png   480w,
      ./assets/images/bg-image/atlas-copco-group-center-sickla_nxzbzn_c_scale,w_893.png   893w,
      ./assets/images/bg-image/atlas-copco-group-center-sickla_nxzbzn_c_scale,w_1185.png 1185w,
      ./assets/images/bg-image/atlas-copco-group-center-sickla_nxzbzn_c_scale,w_1440.png 1440w,
      ./assets/images/bg-image/atlas-copco-group-center-sickla_nxzbzn_c_scale,w_1654.png 1654w
    "
  />
</div>
<main>
  <div class="login-form">
    <div class="my-toolbox-logo">
      <img alt="MyToolbox logo" src="./assets/logo/myToolbox.svg" />
    </div>
    <div class="login-info">
      <p>Please login to access MyToolbox</p>
      <mt-button (submitEvent)="onSubmit()">Login</mt-button>
    </div>
    <hr />
    <div class="contact">
      <p [innerHTML]="message"></p>

      <a href="https://www.atlascopcogroup.com/en/contact-us" target="_blank"
        >Contact customer service</a
      >
    </div>
  </div>
</main>
